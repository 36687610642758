var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c(
        "div",
        {
          staticClass: "alert alert-light alert-elevate fade show",
          attrs: { role: "alert" },
        },
        [
          _vm._m(0),
          _vm.badgeType == "Administrative Badge"
            ? _c("div", { staticClass: "alert-text" }, [
                _vm._v(
                  " Administrative badges are used for administrative classification purposes. They appear in the Student Portfolio Header and are only visible to school staff. "
                ),
              ])
            : _vm._e(),
          _vm.badgeType == "Academic Award"
            ? _c("div", { staticClass: "alert-text" }, [
                _vm._v(
                  " Academic awards are used to recognize students for academic achievements. They are awarded by teachers and staff, and can be shared with guardian and student accounts. "
                ),
              ])
            : _vm._e(),
          _vm.badgeType == "Achievement Level"
            ? _c("div", { staticClass: "alert-text" }, [
                _vm._v(
                  " Achievement badges are ranked levels of achievement that students can earn based on academic performance. Badges are awarded automatically as students meet the requirements. "
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "la la-info-circle kt-font-primary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }