<template>
<b-modal
    ref="badge-import-modal"
    size="lg"
    variant="primary"
    static
    centered
    hide-footer
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Restore Default Badges
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <div
            class="modal-body"
            style="padding: 0px 10px; overflow-x: hidden"
        >
            <div class="kt-portlet__body">
                <div class="kt-section kt-section--last">
                    <div class="kt-section__title">
                        Anecdotal Categories
                    </div>
                    <div class="kt-section__content">
                        <div class="form-group row">
                            <label class="col-3 col-form-label">Import Default Set</label>
                            <div class="col-3">
                                <span class="kt-switch">
                                    <label>
                                        <input
                                            v-model="importAnecdotalCategories"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                            <label class="col-3 col-form-label">Delete Existing</label>
                            <div class="col-3">
                                <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--danger">
                                    <label>
                                        <input
                                            v-model="deleteAnecdotalCategories"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-section kt-section--last">
                    <div class="kt-section__title">
                        Academic Awards
                    </div>
                    <div class="kt-section__content">
                        <div class="form-group row">
                            <label class="col-3 col-form-label">Import Default Set</label>
                            <div class="col-3">
                                <span class="kt-switch">
                                    <label>
                                        <input
                                            v-model="importAcademicAwards"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                            <label class="col-3 col-form-label">Delete Existing</label>
                            <div class="col-3">
                                <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--danger">
                                    <label>
                                        <input
                                            v-model="deleteAcademicAwards"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-section kt-section--last">
                    <div class="kt-section__title">
                        Administrative Badges
                    </div>
                    <div class="kt-section__content">
                        <div class="form-group row">
                            <label class="col-3 col-form-label">Import Default Set</label>
                            <div class="col-3">
                                <span class="kt-switch">
                                    <label>
                                        <input
                                            v-model="importAdministrativeBadges"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                            <label class="col-3 col-form-label">Delete Existing</label>
                            <div class="col-3">
                                <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--danger">
                                    <label>
                                        <input
                                            v-model="deleteAdministrativeBadges"
                                            type="checkbox"
                                        >
                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button
                href="#"
                class="btn btn-primary"
                @click.stop.prevent="importBadges"
            >
                Save Changes
            </button>
        </div>
    </template>
</b-modal>
</template>

<script>
import * as network from '../network';
export default {
    name: 'BadgeImportModal',
    data() {
        return {
            importAnecdotalCategories: true,
            deleteAnecdotalCategories: false,
            importAcademicAwards: true,
            deleteAcademicAwards: false,
            importAdministrativeBadges: true,
            deleteAdministrativeBadges: false,
        };
    },
    methods: {
        open() {
            const { badges } = this.$store.state.database;
            this.deleteAnecdotalCategories = this.$store.state.database.anecdotalEventCategories.length > 0;
            this.deleteAcademicAwards = badges.some(function(b) {
                return b.badgeType === 'Academic Award';
            });
            this.deleteAdministrativeBadges = badges.some(function(b) {
                return b.badgeType === 'Administrative Badge';
            });
            this.$refs['badge-import-modal'].show();
        },
        importBadges() {

            const {showError} = this;
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const {
                importAnecdotalCategories,
                deleteAnecdotalCategories,
                importAcademicAwards,
                deleteAcademicAwards,
                importAdministrativeBadges,
                deleteAdministrativeBadges,
            } = this;
            const params = {
                url: {
                    schoolId, schoolTermId
                },
                body: {
                    settings: {
                        importAnecdotalCategories,
                        deleteAnecdotalCategories,
                        importAcademicAwards,
                        deleteAcademicAwards,
                        importAdministrativeBadges,
                        deleteAdministrativeBadges,
                    },
                },
            };
            const v = this;
            network.badges.setBadgeDefaults(params, function (err, res) {
                if (err) return showError(err);
                window.location.reload();
            });
        },
    },
};
</script>

<style scoped>
.kt-section__desc {
    margin-bottom: 0.4rem;
}
.modal-dialog {
    overflow-y: initial !important;
}
.modal-body {
    max-height: 75vh;
    overflow-y: auto;
}
</style>
