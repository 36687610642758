<template>
<div class="row">
    <div class="col">
        <div
            class="alert alert-light alert-elevate fade show"
            role="alert"
        >
            <div class="alert-icon">
                <i class="la la-info-circle kt-font-primary" />
            </div>
            <div v-if="badgeType == 'Administrative Badge'" class="alert-text">
                Administrative badges are used for administrative classification purposes.
                They appear in the Student Portfolio Header and are only visible to school staff.
            </div>
            <div v-if="badgeType == 'Academic Award'" class="alert-text">
                Academic awards are used to recognize students for academic achievements.
                They are awarded by teachers and staff, and can be shared with guardian and student accounts.
            </div>
            <div v-if="badgeType == 'Achievement Level'" class="alert-text">
                Achievement badges are ranked levels of achievement that students can earn based on academic performance.
                Badges are awarded automatically as students meet the requirements.
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
    name: 'BadgeTypeDescriptions',
    props: {
        badgeType: {
            type: String,
            required: true,
        },
    },
});
</script>

